@import './library.scss';

.mbsc-ios {

    .mbsc-sc-whl-o,
    &.mbsc-calendar .mbsc-sc-whl-o {
        background: transparent;
    }

    .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
    &.mbsc-calendar .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
        background-color: white !important;
    }

    .mbsc-sc-itm:hover {
        background: transparent !important;
    }

    .mbsc-sc-itm:focus {
        background-color: $blue-gray-color !important;
    }

    .mbsc-fr-btn {
        font-weight: normal;
        color: $primary-color;
    }

    &.mbsc-fr-center {
        .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
        .mbsc-fr-btn.mbsc-active {
            background-color: white !important;
        }
        .mbsc-fr-btn-w {
            border-color: $border-color;
        }
    }
}
.mbsc-ios .mbsc-sc-whl-o,
.mbsc-ios.mbsc-calendar .mbsc-sc-whl-o {
    background: transparent !important;
}
.mobi-select {
    padding-left: 2px;

    &.disabled {
        pointer-events: none !important;

        label {
            background-color: #f5f5f5;
        }
    }

    &.hide-icon {
        .mbsc-select,
        label {
            padding: 0 !important;
        }
        .mbsc-select-input {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }

    .mbsc-select,
    label {
        margin: 0 !important;
        margin-top: 1px !important;
        border: 0 !important;
        width: 100% !important;
        font-size: 16px !important;
        border: 1px solid #e3e8ed !important;
        border-radius: 7px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        height: 49px !important;
        color: #d3d3d3 !important;
    }
    .mbsc-select-input {
        width: 100% !important;
        font-size: 16px !important;
        border-radius: 7px !important;
        padding-left: 10px !important;
        padding-right: 20px !important;
        height: 49px !important;
        color: #384250 !important;
        text-align: center !important;
    }

    &.divisions-select {
        .mbsc-select-input {
            text-align: left !important;
            padding-left: 0px !important;
        }
    }
}

.mobi-select {
    &:focus,
    &:focus-within,
    &:focus-visible {
        outline: none;
    }
    *:focus,
    *:focus-within,
    *:focus-visible {
        outline: none;
    }
    &.od-shipto {
        label {
            margin: 0 !important;
            border: 0 !important;
        }
        .od-shipto-input {
            display: flex;
            width: 100%;
            font-size: 16px;
            border-radius: 7px;
            color: #d3d3d3;

            label {
                height: 100%;

                &.mbsc-select.mbsc-input {
                    border: 1px solid #e3e8ed !important;
                    height: 50px;
                    padding: 15px;
                }
            }

            .mbsc-input-wrap {
                input {
                    height: auto;
                    font-size: 16px;
                    padding: 0;

                    &.mbsc-control {
                        position: relative;
                        top: -3px;
                    }
                }
            }
        }
        .mbsc-select-ic {
            color: #d3d3d3;
            top: 1em;
            cursor: pointer;
        }
    }
}

.mbsc-fr-w {
    border-radius: 7px !important;
    background-color: white !important;
}

.mbsc-ios .mbsc-sel-filter-cont.mbsc-input {
    background-color: #e3e8ed !important;
    color: black !important;
    font-size: 14px !important;
}

.mbsc-ios.mbsc-fr-pointer .mbsc-sc-itm-sel {
    color: #22aaec !important;
}

.mbsc-ios.mbsc-fr-pointer .mbsc-sc-whl-multi .mbsc-sc-itm {
    &:hover,
    &:active,
    &:focus,
    &:focus-within,
    &:focus-visible {
        background-color: $blue-gray-color !important;
    }
}

.mbsc-fr,
.mbsc-fr-persp,
.mbsc-fr-overlay {
    height: 100%;
}

@mixin umobi-input() {
    @include basic;

    margin: 0 !important;
    border-radius: $border-radius !important;
    width: 100%;
    height: 50px;
    padding: 0;
    border: 1px solid transparent !important;
    background: transparent !important;

    &:focus,
    &:focus-visible,
    &:focus-within {
        border: 1px solid transparent !important;
        box-shadow: none !important;
        outline: 0 !important;
    }

    input {
        border: 1px solid $border-color !important;
        border-radius: $border-radius !important;
        padding: 0 15px 2px 15px;
        height: 50px;
        background: white !important;
        color: black;
        transition: border-color 0.25s !important;
    }

    input:focus,
    input:focus-visible,
    input:focus-within {
        border: 1px solid $primary-color !important;
    }

    input:disabled {
        opacity: 1;
        color: #c0bfbf !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        @include basic;
        // -webkit-text-fill-color: black;
        -webkit-box-shadow: 0 0 0px 30px white inset;
        box-shadow: 0 0 0px 30px white inset;
        transition: none;
    }
}
