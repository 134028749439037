.fade-out {
    animation: fadeout 1s;
    opacity: 0;
    z-index: -1;
}
@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
