.imgTalk {
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 279px;
  border-radius: 8px;
}

.boxImg {
  display: flex;
}

@media screen and (min-width: 1181px) {
  .imgTalk {
    max-width: 340px;
    max-height: 340px;
  }

  .boxImg {
    .custom-mx {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1133px) {
  .boxImg {
    .custom-mx {
      margin-right: -15px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .btn-coppy{
    width: 340px !important;
    margin: auto;
  }
}

@media screen and (min-width: 900px)  {
  .btn-coppy{
    width: 340px !important;
    margin: auto;
  }
}

@media screen and (max-width: 899px)  {
  .btn-coppy{
    width: unset !important;
    margin: auto;
  }
}
